import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import Certifications from "../components/Repeating/Certifications";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
      <SearchEngineOptimization
        title="About Bella Woof | San Diego Dog Boutique"
        description="Bella Woof provides first-class grooming services, premium pet products, and top-notch training for your best furry friends. Learn more about us here."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <div className="container">
        <section className="pt-2 md:pt-20 pb-20 md:pb-28">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-28 items-center">
            <div className="order-2 md:order-1">
              <h1>About Bella Woof</h1>
              <p>
                Bella Woof is a woman-owned, full-service professional dog & cat boutique that
                provides first-class grooming services, premium pet products, and top-notch training
                and behavior modifications for your best furry friends. We’ve been serving the San
                Diego area since 1998.
              </p>
              <p className="mb-0">
                At Bella Woof, we believe that dogs play an important part in people’s lives and we
                want to help foster that relationship! We’re not just experienced trainers and
                groomers; we’re also animal welfare advocates and educators who are here to provide
                the best care and products for your pets. Bella Woof is also a proud partner of the
                San Diego Humane Society.
              </p>
            </div>
            <div className="order-1 md:order-2">
              <Img fluid={data.intro.childImageSharp.fluid} alt="About Bella Woof" />
            </div>
          </div>
        </section>
      </div>

      <section className="bg-primary_lighter md:rounded-3xl pt-20 md:pt-24 pb-20 md:pb-32 px-4 md:px-6 lg:px-22 md:mb-32">
        <div className="container">
          <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-primary text-opacity-30 mb-14 md:mb-24">
            Our Team
          </p>

          <div className="grid md:grid-cols-12 mb-12 md:mb-20 shadow-primary bg-white rounded-3xl overflow-hidden">
            <div className="col-start-1m md:col-span-4">
              <div className="hidden md:block h-full">
                <Img
                  className="h-full"
                  fluid={data.april.childImageSharp.fluid}
                  alt="April Bove-Rothwell"
                />
              </div>
              <div className="md:hidden">
                <Img fluid={data.aprilMobile.childImageSharp.fluid} alt="April Bove-Rothwell" />
              </div>
            </div>
            <div className="col-start-1m md:col-span-8 px-6 md:px-10 lg:px-20 py-8 md:py-12 lg:py-20">
              <h2>Meet April</h2>
              <p>
                April Bove-Rothwell runs Bella Woof with her husband Robert. She is a licensed
                training specialist and handles all animal training at Bella Woof as well as classes
                at the San Diego Humane Society. April is also a behavior and training volunteer,
                and manages social media, ad writing, and retail product selection for Bella Woof.
              </p>
              <p className="mb-0">
                April has been working with animals professionally for over 20 years with 17 years
                caring for animals in non-profit organizations, most recently as the Senior Gorilla
                Keeper at the San Diego Zoo. Her qualifications include a B.S. in Animal Biology
                from the top animal teaching university in the US, UC Davis, Certification as a
                Professional Dog Trainer (CCPDT-KA), STAR puppy class teacher and AKC CGC test
                evaluator. She enjoys working with clients and their dogs in classes at Bella Woof
                and in customized lessons in their homes.
              </p>
            </div>
          </div>

          <div className="grid md:grid-cols-12 shadow-primary bg-white rounded-3xl overflow-hidden">
            <div className="col-start-1m md:col-span-8 px-6 md:px-10 lg:px-20 py-8 md:py-12 lg:py-20 order-2 md:order-1">
              <h2>Meet Robert</h2>
              <p className="mb-0">
                Robert has 30 years of experience in management, business and leadership positions
                with a B.S. in Manufacturing Engineering from ASU and an Executive MBA from SDSU.
                Robert manages business operations including contracts, financials, and
                partnerships. You’ll also find Robert opening and closing the boutique, managing the
                employees and directing retail sales. Robert also assists in scheduling and
                training. Prior to opening Bella Woof with April, Robert owned his own consulting
                firm and worked in the aerospace engineering industry for nearly three decades.
              </p>
            </div>
            <div className="col-start-1m md:col-span-4 order-1 md:order-2">
              <div className="hidden md:block h-full">
                <Img className="h-full" fluid={data.robert.childImageSharp.fluid} alt="Robert" />
              </div>
              <div className="md:hidden">
                <Img fluid={data.robertMobile.childImageSharp.fluid} alt="Robert" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="relative bg-secondary_light md:rounded-3xl px-6 md:px-14 lg:px-28 pt-20 md:pt-24 pb-16 md:pb-20 mb-20 md:mb-32">
          <div className="absolute top-0 left-0 right-0 text-center -mt-16">
            <div className="hidden md:block">
              <Img fixed={data.bellaIcon.childImageSharp.fixed} alt="Bella icon" />
            </div>
          </div>
          <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-secondary text-opacity-30 mb-12 md:mb-24 md:text-center">
            Our Core Values
          </p>

          <header className="md:text-center max-w-2xl mx-auto mb-14 md:mb-18">
            <h3>At Bella Woof, we let the following core values guide our business. </h3>
          </header>

          <div className="grid md:grid-cols-2 md:gap-x-20">
            <div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">
                  Top-Notch Service
                </p>
                <ul>
                  <li className="mb-3">We only hire experienced groomers and bathers.</li>
                </ul>
              </div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">Expertise</p>
                <ul>
                  <li className="mb-3">
                    Our certified trainer teaches dogs and owners in a safe and effective manner.
                  </li>
                </ul>
              </div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">
                  Proven Experience
                </p>
                <ul>
                  <li className="mb-3">
                    We carefully choose and carry products that are natural, holistic, and
                    professionally made.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">Animal Welfare</p>
                <ul>
                  <li className="mb-3">
                    We are advocates for your furry companions and treat them like family.
                  </li>
                </ul>
              </div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">
                  Stress-Free Animal Care
                </p>
                <ul>
                  <li className="mb-3">
                    Our grooming services are cage-free and each groomer works with one dog at a
                    time to get the attention and care they deserve.
                  </li>
                </ul>
              </div>
              <div className="mb-10">
                <p className="font-heading text-gray-900 font-bold text-lg mb-5">Comprehensive</p>
                <ul>
                  <li className="mb-3">
                    We are a full service professional dog spa that always strives to go above and
                    beyond your expectations.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Certifications className="mb-20 md:mb-32" />

      <TestimonialBackgroundImage />

      <CTABackgroundColor>
        <h2>Give your dog good things.</h2>
        <p>
          Your dogs deserve the finer things in life. We carry pet-approved food, treats, and pet
          supplies made by dog lovers like you! Call or send us a message to find out more about our
          retail products.
        </p>
      </CTABackgroundColor>

      <InstagramFeed />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(relativePath: { eq: "open-graph/facebook/About_FB.jpg" }) {
      publicURL
    }
    twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/About_Twitter.jpg" }) {
      publicURL
    }
    intro: file(relativePath: { eq: "about/1.0-About-Hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1256, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    april: file(relativePath: { eq: "about/April.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aprilMobile: file(relativePath: { eq: "about/Meet April_Mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 686, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robert: file(relativePath: { eq: "about/Robert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robertMobile: file(relativePath: { eq: "about/Meet Robert_Mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 686, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bellaIcon: file(relativePath: { eq: "about/Bella Icon.png" }) {
      childImageSharp {
        fixed(width: 144, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default Page;
